import styled, { css } from 'styled-components';
import { breakpointUp, spacer, variable } from 'theme';
import { BREAKPOINTS } from '../../../../constants/breakpoints';
import Grid from '../../../Grid/Grid';
import ContentfulTileCategory from '../ContentfulTileCategory/ContentfulTileCategory';

interface StyledGroupingTilesProps {
  count?: number;
}

export const StyledGroupingTiles = styled(Grid)<StyledGroupingTilesProps>`
  gap: ${spacer(100)};
  grid-template-columns: 1fr;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css<StyledGroupingTilesProps>`
      grid-template-columns: ${({ count = 0 }) => count > 1 && `1fr 1fr`};
    `
  )}
`;

export const StyledContentfulTileCategory = styled(ContentfulTileCategory)`
  img {
    transition: ${variable('transition')};
  }

  &:hover {
    img {
      transform: scale(1.025);
    }
  }
`;
