import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';
import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import { BREAKPOINTS } from 'constants/breakpoints';
import { EmblaOptionsType } from 'embla-carousel';
import breakpointValues from 'theme/variables/breakpoints';
import { IGroupingCategories } from 'types/ContentfulTypes';
import ContentfulGroupingWrapper from '../ContentfulGroupingWrapper/ContentfulGroupingWrapper';
import { ContentfulTileCategoryProps } from '../ContentfulTileCategory/ContentfulTileCategory';
import { StyledContentfulTileCategory } from './ContentfulGroupingCategories.styled';

interface ContentfulGroupingCategoriesProps {
  cardSize?: ContentfulTileCategoryProps['size'];
  className?: string;
  content: IGroupingCategories;
  slidesObject?: SlidesObject;
}

const ContentfulGroupingCategories = ({
  cardSize,
  className,
  content,
  slidesObject,
}: ContentfulGroupingCategoriesProps) => {
  if (!content?.fields) {
    return null;
  }

  const { items, title } = content.fields;

  const options: EmblaOptionsType = {
    breakpoints: {
      [`(min-width: ${breakpointValues.md}px)`]: { slidesToScroll: 3 },
      [`(min-width: ${breakpointValues.lg}px)`]: { slidesToScroll: 4 },
      [`(min-width: ${breakpointValues.xl}px)`]: { slidesToScroll: 5 },
    },
  };

  const showDots = { [BREAKPOINTS.XS]: false };
  const showArrows = { [BREAKPOINTS.MD]: true };
  const slidesToShow: SlidesObject = {
    [BREAKPOINTS.XS]: 1.5,

    [BREAKPOINTS.MD]: 3.5,
    [BREAKPOINTS.LG]: 4,
    [BREAKPOINTS.XL]: 5,
  };

  return (
    <ContentfulGroupingWrapper className={className}>
      <SliderEmbla
        options={slidesObject ? undefined : options}
        showDots={showDots}
        showArrows={showArrows}
        title={title}
        titleTag="h3"
        titleType="h2"
        slidesToShow={slidesObject || slidesToShow}
      >
        {items?.map((item) => (
          <StyledContentfulTileCategory key={item.sys.id} content={item} size={cardSize} />
        ))}
      </SliderEmbla>
    </ContentfulGroupingWrapper>
  );
};

export default ContentfulGroupingCategories;
